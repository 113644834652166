/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'

// Third Party
import CookieConsent from 'react-cookie-consent'

// Theme
import Theme from 'styles/Theme'

// Components
import Footer from 'components/Footer'
import Header from 'components/Header'

// CSS
import 'styles/all.scss'
import { Helmet } from 'react-helmet'

const Layout = ({ children, partialHeader, scalableFooter }) => (
  <Theme>
    <Helmet>
      <script src="https://apps.elfsight.com/p/platform.js" defer />
    </Helmet>
    <Header partialHeader={partialHeader} />
    <>
      {children}
    </>
    <Footer scalableFooter={scalableFooter} />
    <CookieConsent
      acceptOnScroll
      acceptOnScrollPercentage={20}
      location="bottom"
      style={{ fontWeight: 800, textAlign: 'center', backgroundColor: '#FF5C00', color: '#FFFFFF'}}
      buttonClasses="d-none"
    >
      We gebruiken cookies om ervoor te zorgen dat onze website zo soepel mogelijk draait. Als u doorgaat met het gebruiken van de website, gaan we er vanuit dat u ermee instemt.
    </CookieConsent>
  </Theme>
)

export default Layout
