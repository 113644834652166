import React, { useState } from 'react'
import { navigate } from 'gatsby'

// Images
import Chevron from 'img/chevron-down-feather.inline.svg'

// Third Party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

const Options = styled(motion.div)`
  position: absolute;
  padding: 10px 0 10px 0;
  background: ${props => props.theme.color.text.light};
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
  pointer-events: none;
  width: 200px;
  border-radius: 5px;
  z-index: 99;

  ${props => props.bigLabel ? css`
    @media screen and (max-width: 991px) {
      left: -100px;
      z-index: 50;
    }
  ` : css`
    @media screen and (max-width: 991px) {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      z-index: 50;
    }
  `}
`

const Label = styled.div`
  ${props => props.bigLabel ? css`
    font-size: 28px;
    font-weight: 700;

    @media screen and (max-width: 991px) {
      font-size: 20px;
      font-weight: 700;
      svg {
        position: absolute;
        right: -30px;
      }
    }
  ` : css`
    @media screen and (max-width: 991px) {
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 20px;
      svg {
        position: absolute;
        right: 33%;
      }
    }
  `}
`

const StyledUBOSelect = styled.div`
  position: relative;

  ${props => props.isOpen && css`
    & > ${Options} {
      pointer-events: all;
    }
  `}

  ${props => props.nested && css`
    ${Label} {
      @media screen and (max-width: 991px) {
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 0;
      }
    }

    ${Options} {
      left: 150px;
      top: -10px;

      @media screen and (max-width: 991px) {
        left: 50px;
        top: 35px;
      }
    }
  `}
`

const Option = styled(motion.div)`
  p {
    text-align: left;
    padding: 10px 20px 10px 20px;
  }

  svg {
    margin: 10px 10px 10px 10px;

    @media screen and (max-width: 991px) {
      position: relative;
      right: unset;
    }
  }
  
  cursor: pointer;
`

const StyledChevron = styled(Chevron)`
  transition: .25s;

  ${props => !props.isOpen && css`
    transform: rotate(-90deg);
  `}
`

const UBOSelect = ({ bigLabel, nested, placeholder, options, className, styling }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <StyledUBOSelect 
      isOpen={isOpen}
      className={`${className ? `${className}` : ``}`}
      onFocus={() => setIsOpen(true)}
      onMouseOver={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      nested={nested}
    >
      <Label 
        className={`d-flex align-items-center ${nested ? `justify-content-between`: `justify-content-center justify-content-lg-between`}`}
        bigLabel={bigLabel}
      >
        <p className="mb-0 mr-lg-3">{placeholder}</p>
        <StyledChevron isOpen={isOpen} />
      </Label>
      <Options
        initial={{ opacity: 0 }}
        animate={isOpen ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.15
        }}
        bigLabel={bigLabel}
      >
        {options.map((o, index) => (
          <Option
            initial={{ opacity: 0, x: 10, background: styling ? styling.background || '#FFFFFF' : '#FFFFFF', color: styling ? styling.color || '#002850' : '#002850' }}
            animate={isOpen ? { opacity: 1, x: 0, transition: { delay: 0.1 + (index * 0.1) } } : { opacity: 0, x: 10 }}
            exit={{ opacity: 0, x: 10 }}
            whileHover={{ background: styling ? styling.hoverBackground || '#002850' : '#002850', color: styling ? styling.hoverColor || '#FFFFFF' : '#FFFFFF', transition: { duration: 0.01, delay: 0 }}}
            onClick={() => !o.options && (navigate(o.value, { replace: true }))}
          >
            {o.options ? (
              <>
                <UBOSelect
                  options={o.options}
                  placeholder={o.label}
                  nested
                />
              </>
            ) : (
              <p className="mb-0">{o.label}</p> 
            )}
            
          </Option>
        ))}
      </Options>
    </StyledUBOSelect>
  )
}

export default UBOSelect