// eslint-disable-next-line import/no-cycle

const API = {
    async get(settings) {
      const newSettings = { ...{ token: true, route: '', url: '', data: {}, response: null }, ...settings }
  
      const fetchRequest = fetch(
        newSettings.url ? newSettings.url : `${this.URL}${newSettings.route}`,
        {
          method: 'GET',
          headers: {
            ...{
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
          },
        }
      )
  
      if(newSettings.response === 'return') {
        return fetchRequest
      }
  
      const response = await fetchRequest
      const json = await response.json()
  
      return { data: json, status: response.status, errors: json.errors }
    },

    async xml(settings) {
      const newSettings = { ...{ token: true, route: '', url: '', data: {}, response: null }, ...settings }

      const fetchRequest = fetch(
        newSettings.url ? newSettings.url : `${this.URL}${newSettings.route}`,
          {
            method: 'GET'
          }
      )

        if(newSettings.response === 'return') {
          return fetchRequest
        }

        const response = await fetchRequest

        const responseXML = await response.text()

        return { data: responseXML } 
        // const json = await response.json()

        // return { data: json, status: response.status, errors: json.errors }
    }
  }
  
  export default API
  