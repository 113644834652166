import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import _ from 'lodash'

// Components

// Images
import footer from 'img/Snorkel_footer.svg'
import FaqPuppet from 'img/snorkel_wachtlijst_2.svg'

// Third Party
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'

const FooterBackgroundImage = styled.img`
  transition: 0.25s;
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 355px;
  pointer-events: none;

  @media screen and (max-width: 991px) {
    width: 2000px;
    height: 500px;
    left: -800px;
  }
`

const FaqImage = styled.div``

const StyledFooter = styled.div`
  position: relative;

  ${props => props.scalableFooter ? css`
    margin-top: -210px;
  ` : css`
    /* margin-top: -100px; */
  `}


  &::before {
    content: "";
    position: absolute;
    background-color: ${props => props.theme.color.blue};
    width: 100%;
    bottom: -200px;
    left: 0;
    height: 400px;
  }

  .elfsight-app-137f8312-55c2-4b05-bdbb-2cf14f6531f9 {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 50;
  }
`

const LocationLinks = styled.div`
  p {
    &:hover {
      text-decoration: underline;
    }

    a {
      color: ${props => props.theme.color.text.light};
    }
  }
`

const BlueTopper = styled.div`
  height: 50px;
`

const StyledLink = styled(Link)`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &::after {
    z-index: 500;
    content: "Veelgestelde vragen";
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    left : 215px;
    height: 100px;
    width: 100px;
    color: ${props => props.theme.color.contrast};
    pointer-events: none;
  }
`

const StyledPuppet = styled.img`
  position: absolute;
  bottom: -5px;
  left: 80px;
  width: 100px;
  height: 115px;
  z-index: 3;
`

const Bubble = styled(motion.div)`
  background-color: #FFFFFF;

  @media screen and (max-width: 991px) {
    background-color: #f3f3f3;
  }

  z-index: 4;

  ${props => props.width && css`
    width: ${props.width};
  `}

  ${props => props.height && css`
    height: ${props.height};
  `}

  ${props => props.left && css`
    left: ${props.left};
  `}
  
  ${props => props.bottom && css`
    bottom: ${props.bottom};
  `}

  border-radius: 100%;
  position: absolute;
`

const Footer = ({ scalableFooter }) => {
  const { wordpressPage: { acf: info }, allWordpressWpLocaties: { edges: locations } } = useStaticQuery(
    graphql`
      query footerQuery {
        wordpressPage(slug: {eq: "contact-informatie"}) {
          acf {
            btw
            phonenumber
            kvk_number
            email
          }
        }
        allWordpressWpLocaties {
          edges {
            node {
              slug
              title
              acf {
                location_title
              }
            }
          }
        }
      }
    `
  )

  const options = []

  locations.forEach(({ node }) => {
    const subLocations = locations.filter(({ node: { acf: { location_title: locationTitle } } }) => locationTitle === node.acf.location_title)
    const edges = { label: node.acf.location_title, value: `/locatie/${node.slug}` }

    if (options.find(({ label }) => label === node.acf.location_title) === undefined) {
      if (subLocations.length > 1) {
        options.push({ ...{ options: subLocations.map(({ node: subNode }) => { return { label: subNode.acf.secondary_location, value: `/locatie/${subNode.slug}` } }) }, ...edges })
      } else {
        options.push(edges)
      }
    }
  })

  return (
    <StyledFooter scalableFooter={scalableFooter}>
      <FooterBackgroundImage className="d-lg-flex d-none" loading="eager" fadeIn={false} scalableFooter={scalableFooter} alt="" src={footer} />
      <BlueTopper className="d-lg-none d-flex color-background-lightblue" />
      <div className="color-background-blue">
        <div className="container pt-lg-0 pt-4">
          <div className="row">
            {scalableFooter ? (
              <div className="col-lg-6" />
            ) : (
              <FaqImage className="position-relative left text-center col-6 d-lg-block d-none">
                <StyledLink className="d-flex position-relative" to='/faq'>
                  <StyledPuppet alt="" src={FaqPuppet} />
                  <Bubble bottom="50px" left="185px" height="15px" width="15px" animate={{ scale: [1, 1.2, 1], opacity: 1 }} initial={{ scale: 0.3, opacity: 0 }} transition={{ duration: 0.7 }} />
                  <Bubble bottom="75px" left="200px" height="19px" width="19px" animate={{ scale: [1, 1.2, 1], opacity: 1 }} initial={{ scale: 0.3, opacity: 0 }} transition={{ delay: .3, duration: 0.7 }} />
                  <Bubble bottom="90px" left="155px" height="37px" width="37px" animate={{ scale: [1, 1.2, 1], opacity: 1 }} initial={{ scale: 0.3, opacity: 0 }} transition={{ delay: .6, duration: 0.7 }} />
                  <Bubble bottom="80px" left="215px" height="101px" width="101px" animate={{ scale: [1, 1.2, 1], opacity: 1 }} initial={{ scale: 0.3, opacity: 0 }} transition={{ delay: .9, duration: 0.7 }} />
                </StyledLink>
              </FaqImage>
            )}
            <div className="col-lg-6">
              <div className="row pb-4">
                <div className="col-sm-6 px-lg-0 px-5">
                  <h2 className="font-size-m font-weight-xl">Zwemschool Snorkeltje</h2>
                  <p className="mb-0 font-size-m color-text-light">{`KvK: ${info.kvk_number}`}</p>
                  <p className="mb-0 font-size-m color-text-light">{`BTW: ${info.btw}`}</p>
                  <a className="mb-0 font-size-m color-text-light" href="/algemene-voorwaarden/">
                    Algemene voorwaarden
                  </a>
                </div>
                <div className="col-sm-6 px-lg-0 px-5 pt-sm-0 pt-4">
                  <h2 className="font-size-m font-weight-xl">Locaties</h2>
                  <LocationLinks className="row">
                    {_.map(options, (location, key) => (
                      <p className="mb-2 font-size-m color-text-light col-lg-6" key={key}>
                        <Link to={location.value}>
                          {parse(location.label)}
                        </Link>
                      </p>
                    ))}
                  </LocationLinks>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="elfsight-app-137f8312-55c2-4b05-bdbb-2cf14f6531f9" />
    </StyledFooter>
  )
}

export default Footer
