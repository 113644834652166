import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import _ from 'lodash'

const Social = () => {
  const { socialLinks } = useStaticQuery(graphql`
    {
      socialLinks: wordpressWpComponents(wordpress_id: { eq: 357 }) {
        acf {
          social {
            socialLink: social_link {
              url
              icon {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div>
      {socialLinks.acf.social.socialLink.map(({ url, icon }) => (
        <a href={url} target="_blank" class="ml-2"><img src={icon.localFile.publicURL} width="18"/></a>
      ))}
    </div>
  )
}

export default Social
