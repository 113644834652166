/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { CSSTransition } from 'react-transition-group'
import StarRatings from 'react-star-ratings'

// Components
import UBOSelect from 'components/UBOSelect'
import Social from 'components/Social'

// Images
import imgLogo from 'img/logo.svg'
import WhiteCheckMark from 'img/whiteCheckMark.inline.svg'
import close from 'img/close.svg'
import menu from 'img/menu.svg'

// Helpers
import API from 'helpers/API'

// Third Party
import styled, { css } from 'styled-components'
import he from 'he'
import parser from 'fast-xml-parser'

const StyledWhiteCheckMark = styled(WhiteCheckMark)`
  height: 35px;
`

const StyledHeader = styled.nav`
  z-index: 20;
  position: relative;

  .react-select-container {
    .react-select {
      &__control {
        border: none;
      }

      &__indicator-separator {
        display: none;
      }

      &__single-value {
        color: ${props => props.theme.color.text.main};
      }

      &__placeholder {
        color: ${props => props.theme.color.text.main};
      }
    }
  }

  .header-menu-enter {
    opacity: 0;
  }

  .header-menu-enter-active {
      opacity: 1 !important;
      transition: opacity 300ms, transform 300ms;
  }

  .header-menu-exit {
      opacity: 1;
  }

  .header-menu-exit-active {
      opacity: 0;
      transition: opacity 300ms, transform 300ms;
  }
`

const HeaderTop = styled.div`
  height: 40px;
  background-color: ${props => props.theme.color.contrast};

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const HeaderTopItems = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`

const HeaderTopItemsItem = styled.div`
  ${props => props.left && css`
    margin-right: 20px;
  `}

  ${props => props.reviewLink &&  css`
    text-decoration: underline;
    color: ${props.theme.color.main};
    cursor: pointer;

    &::after {
      content: ">";
    }
  `}
`

const NavbarCollapseButton = styled.button`
  z-index: 100;
  width: 60px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;

  &-stripe {
    background-color: ${props => props.theme.color.main};
    height: 2px;
    width: 100%;
    border-radius: 2px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
`

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media screen and (max-width: 991px) {
    z-index: 2;
  }
`

const NavbarItemImg = styled.img`
  height: 105px;
  margin-bottom: -22px;
  z-index: 4;
  position: relative;

  @media screen and (max-width: 483px) {
    height: 70px;
  }
`

const Header = ({ currentLocation, partialHeader }) => {
  const [isCollapsed, setCollapsed] = useState(true);

  const [xml, setXml] = useState(null)

  useEffect(() => {
    const getXml = async() => {
      const options = {
        attributeNamePrefix : "@_",
        attrNodeName: "attr",
        textNodeName : "#text",
        ignoreAttributes : true,
        ignoreNameSpace : false,
        allowBooleanAttributes : false,
        parseNodeValue : true,
        parseAttributeValue : false,
        trimValues: true,
        cdataTagName: "__cdata",
        cdataPositionChar: "\\c",
        parseTrueNumberOnly: false,
        arrayMode: false,
        attrValueProcessor: val => he.decode(val, {isAttributeValue: true}),
        tagValueProcessor : val => he.decode(val),
        stopNodes: ["parse-me-as-string"]
      }
      const { data } = await API.xml({url: "https://www.klantenvertellen.nl/v1/review/feed.xml?hash=orclkww0qqg8hln"})
    
      const tObj = parser.getTraversalObj(data, options);
      const jsonObj = parser.convertToJson(tObj,options);
    
      setXml(jsonObj.ReviewFeedDto)
    }

    getXml()
  }, [])

  return (
    <StyledHeader className="position-relative">
      <HeaderTop>
        <div className="container d-flex justify-content-between h-100 align-items-center">
          <HeaderTopItems className="color-text-secondary d-flex">
            <HeaderTopItemsItem left className="font-size-m font-weight-l">
              <StyledWhiteCheckMark className="mr-2" />
              Kwaliteit
            </HeaderTopItemsItem>
            <HeaderTopItemsItem left className="font-size-m font-weight-l">
              <StyledWhiteCheckMark className="mr-2" />
              Snelheid
            </HeaderTopItemsItem>
            <HeaderTopItemsItem left className="font-size-m font-weight-l">
              <StyledWhiteCheckMark className="mr-2" />
              Duidelijkheid
            </HeaderTopItemsItem>
          </HeaderTopItems>
          <HeaderTopItems className="color-text-secondary d-flex">
            <HeaderTopItemsItem left className="font-size-m font-family-third font-weight-l">
              Nu en later, vol vertrouwen in het water!
            </HeaderTopItemsItem>
          </HeaderTopItems>
          <HeaderTopItems className="color-text-secondary d-flex">
            <div className="star-rating-container d-flex align-items-center">
              <StarRatings
                rating={!xml ? 0 : xml.averageRating / 2}
                starRatedColor="white"
                numberOfStars={5}
                name='rating'
                starDimension="20px"
                starSpacing="0px"
              />
              <p className="font-size-xm ml-3 mb-0 color-text-light">
                <span className="font-weight-xl">{!xml ? `...` : xml.averageRating}</span>
                {` / 10`}
              </p>
            </div>
            <HeaderTopItemsItem reviewLink className="ml-3">
              <a target="_blank" rel="noopener noreferrer" href="https://klantenvertellen.nl/reviews/1061877/Zwemschool%20Snorkeltje" className="color-text-light">{`uit ${!xml ? `...` : xml.numberReviews} reviews`}</a>
            </HeaderTopItemsItem>
          </HeaderTopItems>
          <HeaderTopItems>
            <HeaderTopItemsItem>
              <Social />
            </HeaderTopItemsItem>
          </HeaderTopItems>
        </div>
      </HeaderTop>
      <NavbarContainer className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <NavbarItemImg src={imgLogo} alt="" />
          </Link>
        </div>

        {partialHeader ? (
          <>
            <NavbarCollapseButton type="button" onClick={() => setCollapsed(!isCollapsed)} className={`${isCollapsed ? ' navbar-collapse-button-collapsed' : ''} text-center d-block d-lg-none`}>
              {isCollapsed ? (
                <img alt="open-menu" src={menu} />
              ) : (
                <img alt="close-menu" src={close} />
              )}
            </NavbarCollapseButton>
            <HeaderMenuMobile xml={xml} partialHeader={partialHeader} currentLocation={currentLocation} isCollapsed={isCollapsed} setCollapse={setCollapsed} />
            <HeaderMenuDesktop partialHeader={partialHeader} currentLocation={currentLocation} />
          </>
          ) : (
            <>
              <NavbarCollapseButton type="button" onClick={() => setCollapsed(!isCollapsed)} className={`${isCollapsed ? ' navbar-collapse-button-collapsed' : ''} text-center d-block d-lg-none`}>
                {isCollapsed ? (
                  <img alt="open-menu" src={menu} />
                ) : (
                  <img alt="close-menu" src={close} />
                )}
              </NavbarCollapseButton>
              <HeaderMenuMobile xml={xml} currentLocation={currentLocation} isCollapsed={isCollapsed} setCollapse={setCollapsed} />
              <HeaderMenuDesktop currentLocation={currentLocation} />
            </>
          )}
      </NavbarContainer>
    </StyledHeader>
  )
}

const OtherLogo = styled.img`
  position: absolute;
  left: 15px;
  top: 5px;
  width: 160px;
  height: 70px !important;
`

const NavbarItems = styled.ul`
  left: 0;
  z-index: 10;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 0;

  @media screen and (max-width: 991px) {
    background-color: ${props => props.theme.color.secondary};
    visibility: visible;
    text-align: center;
    width: 100%;
    flex-wrap: wrap;
    padding-left: 0;
    background: #fff;
    margin: auto;
    transition: 350ms;
  }
`

const NavbarMenu = styled.div`
  @media screen and (max-width: 991px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 60;
    background: #fff;
  }
`

const HeaderMenuMobile = ({ xml, currentLocation, isCollapsed, partialHeader }) => (
  <CSSTransition in={!isCollapsed} timeout={300} unmountOnExit classNames="header-menu">
    <NavbarMenu>
      <NavbarItems className="pt-5 mt-5">
        <Link to="/">
          <OtherLogo loading="eager" fadeIn={false} src={imgLogo} alt="" />
        </Link>
        <HeaderMenuItems xml={xml} mobile partialHeader={partialHeader} currentLocation={currentLocation} />
      </NavbarItems>
    </NavbarMenu>
  </CSSTransition>
)

const HeaderMenuDesktop = ({ currentLocation, partialHeader }) => (
  <NavbarItems className='d-none d-lg-flex'>
    <HeaderMenuItems partialHeader={partialHeader} currentLocation={currentLocation} />
  </NavbarItems>
)

const NavbarLi = styled.li`
  position: relative;

  a {
    color: ${props => props.theme.color.text.main};
    &[aria-current] {
      font-weight: ${props => props.theme.font.weight.xl};
    }
  }

  ${props => props.select && css`
    width: 200px;
    cursor: pointer;

    select {
      cursor: pointer;
      font-weight: ${props.theme.font.weight.xl};
      border: none;
      appearance:none;
      position: relative;

      option {
        &[aria-current] {
          font-weight: ${props.theme.font.weight.xl};
        }
      }
    }    
    
    @media screen and (max-width: 991px) {
      width: 100% !important;
      justify-content: center;
      display: flex;

      .react-select-container {
        width: 120px;
        margin-top: 20px;
        margin-bottom: 5px;
      }
    }
  `}

  @media screen and (max-width: 991px) {
    padding-left: 0 !important;
    font-size: ${props => props.theme.font.size.xm};
    font-weight: ${props => props.theme.font.weight.l};
    transition: 0.15s;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

const TopperMobile = styled.div`
  position: absolute;
  bottom: 15%;
  background-color: ${props => props.theme.color.contrast};
  width: 95%;
  border-radius: 10px;
  margin-top: 30px;
  padding-bottom: 1rem;
`

const StyledUBOSelect = styled(UBOSelect)`
  @media screen and (max-width: 991px) {
    width: 100%;
  }
`

const HeaderMenuItems = ({ xml, mobile, partialHeader }) => {

  const { allLocations: {edges: locations}, aanbod: {edges: lesaanbod}} = useStaticQuery(graphql`
    query AllLocationsQuery {
      allLocations: allWordpressWpLocaties {
        edges {
          node {
            slug
            title
            wordpress_id
            acf {
              location_title
              secondary_location
            }
          }
        }
      }

      aanbod: allWordpressPage(filter: {parent_element: {slug: {eq: "lesaanbod"}}}, sort: {fields: id, order: DESC}) {
        edges {
          node {
            title
            wordpress_id
            slug
          }
        }
      }
    }
  `)

  const options = []

  locations.forEach(({ node }) => {
    const subLocations = locations.filter(({ node: { acf: { location_title: locationTitle } } }) => locationTitle === node.acf.location_title)
    const edges = { label: node.acf.location_title, value: `/locatie/${node.slug}` }

    if(options.find(({ label }) => label === node.acf.location_title) === undefined) {
      if(subLocations.length > 1) {
        options.push({ ...{ options: subLocations.map(({ node: subNode }) => { return { label: subNode.acf.secondary_location, value: `/locatie/${subNode.slug}` } }) }, ...edges })
      } else {
        options.push(edges)
      }
    }
  })

  const aanbodOptions = []

  lesaanbod.forEach(({ node }) => {
    const aanbodEdges = { label: node.title, value: `/lesaanbod/${node.slug}` }

    aanbodOptions.push(aanbodEdges)

  })

  return (
    <>
      {!partialHeader && (
        <StyledUBOSelect
          options={options}
          placeholder="Locaties"
        />
      )}
      <StyledUBOSelect
        options={aanbodOptions}
        placeholder="Lesaanbod"
      />
      <NavbarLi className="mb-lg-0 mb-3">
        <Link to="/wachtlijst">
          Inschrijven
        </Link>
      </NavbarLi>
      <NavbarLi className="mb-lg-0 mb-3">
        <Link to="/over-ons">
          Over ons
        </Link>
      </NavbarLi>
      <NavbarLi className="mb-lg-0 mb-3">
        <Link to="/vacatures">
          Vacatures
        </Link>
      </NavbarLi>
      <NavbarLi className="mb-lg-0 mb-3">
        <Link to="/contact">
          Contact
        </Link>
      </NavbarLi>
      {mobile && (
        <div className="d-flex w-100 justify-content-center">
          <TopperMobile className="pt-3">
            <p className="font-weight-xl mb-0">Nu en later, vol vertrouwen in het water!</p>
            <div>
              <HeaderTopItems className="d-flex pl-1 justify-content-center">
                <HeaderTopItemsItem left className="font-size-m font-weight-l">
                  <StyledWhiteCheckMark className="mr-2" />
                  Kwaliteit
                </HeaderTopItemsItem>
                <HeaderTopItemsItem left className="font-size-m font-weight-l">
                  <StyledWhiteCheckMark className="mr-2" />
                  Snelheid
                </HeaderTopItemsItem>
                <HeaderTopItemsItem left className="font-size-m font-weight-l">
                  <StyledWhiteCheckMark className="mr-2" />
                  Duidelijkheid
                </HeaderTopItemsItem>
              </HeaderTopItems>
              <div className="d-flex justify-content-center">
                <div className="star-rating-container d-flex align-items-center">
                  <StarRatings
                    rating={!xml ? 0 : xml.averageRating / 2}
                    starRatedColor="white"
                    numberOfStars={5}
                    name='rating'
                    starDimension="20px"
                    starSpacing="0px"
                  />
                  <p className="font-size-xm ml-3 mb-0 color-text-light">
                    <span className="font-weight-xl">{!xml ? `...` : xml.averageRating}</span>
                    {` / 10`}
                  </p>
                </div>
                <HeaderTopItemsItem reviewLink className="ml-3">
                  <a target="_blank" rel="noopener noreferrer" href="https://klantenvertellen.nl/reviews/1061877/Zwemschool%20Snorkeltje" className="color-text-light">{`uit ${!xml ? `...` : xml.numberReviews} reviews`}</a>
                </HeaderTopItemsItem>
              </div>
            </div>
          </TopperMobile>
        </div>
      )}
    </>
  )
}

export default Header

